import { Form } from "../../class/Form";

// const LISTE_ID_PAYS = Object.freeze([5,4,2,13,7,9,12,10,6,8,11,16,15,14,99,1])

export function checkValueForm(data:{form:Form, etape:number})
{
    const form = data.form
    const etape = data.etape

    console.log(`controle etape ${etape}`)

    if( etape == 4 )
    {
        return checkEtape4(form)
    }
    else if( etape == 5 )
    {
        return checkEtape5(form)
    }
    else
    {
        return false
    }
}

function checkEtape4(form:Form)
{
    const hasAllProperties = checkHasPropertyEtape4(form)
    const hasImportantValues = checkHasImportantValuesEtape4(form)

    if( hasAllProperties && hasImportantValues )
    {
        return true
    }
    else
    {
        return false
    }
}

function checkEtape5(form:Form)
{
    const hasAllProperties = checkHasPropertyEtape5(form)
    const hasImportantValues = checkHasImportantValuesEtape5(form)
    if( hasAllProperties && hasImportantValues )
    {
        return true
    }
    else
    {
        return false
    }
}


function checkHasPropertyEtape4(form:Form)
{
    if( 
        form?.hasOwnProperty("_civilite_abo")
        &&
        form?.hasOwnProperty("_lien_abo")
        &&
        form?.hasOwnProperty("_nom_abo")
        &&
        form?.hasOwnProperty("_prenom_abo")
        &&
        form?.hasOwnProperty("_email_abo") 
        &&
        form?.hasOwnProperty("_societe_organisme_abo")
        &&
        form?.hasOwnProperty("_ref_dossier") 
        &&
        form.hasOwnProperty("_civilite")
        &&
        form.hasOwnProperty("_nom") 
        &&
        form.hasOwnProperty("_prenom")
        &&
        form.hasOwnProperty("_email")
        &&
        form.hasOwnProperty("_societe_organisme")
        &&
        form.hasOwnProperty("_type_contrat")
        &&
        form.hasOwnProperty("_info_eau_potable")
        &&
        form.hasOwnProperty("_info_assainissement_collectif")
    ) 
    {
        return true
    }
    else 
    {
        return false
    }
}


function checkHasPropertyEtape5(form:Form)
{
    if( 
        form.hasOwnProperty("_litige")
        &&
        form.hasOwnProperty("_attentes")
        &&
        form.hasOwnProperty("_adresse")
        &&
        form.hasOwnProperty("_cp")
        &&
        form.hasOwnProperty("_ville")
        &&
        form.hasOwnProperty("_pays")
        &&
        form.hasOwnProperty("_adresse_correspondance")
        &&
        form.hasOwnProperty("_cp_correspondance")
        &&
        form?.hasOwnProperty("_ville_correspondance")
        &&
        form?.hasOwnProperty("_pays_correspondance")
        &&
        form?.hasOwnProperty("_complement_adresse_correspondance")
    ) 
    {
        return true
    }
    else 
    {
        return false
    }
}


function checkHasImportantValuesEtape4(form:Form)
{
    if( 
        Number(form['_civilite']) == 0
        ||
        form['_nom'] == "" 
        ||
        form['_prenom'] == ""
        ||
        form['_email'] == ""
        ||
        Number(form['_type_contrat']) == 0
    ) 
    {
        return false
    }
    else 
    {
        return true
    }
}

function checkHasImportantValuesEtape5(form:Form)
{
    if( 
        form['_litige'] == ""
        ||
        form['_attentes'] == "" 
        ||
        form['_adresse'] == ""
        ||
        form['_cp'] == "" 
        ||
        form['_ville'] == ""
    ) 
    {
        return false
    }
    else 
    {
        return true
    }
}
