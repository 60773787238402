import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { User } from '../../interfaces/user';


@Injectable({
  providedIn: 'root'
})
export class UserService 
{
  constructor(
    private httpClient: HttpClient
  ) { }

  getUserData(id_user:number):Observable<User[]>
  {
    const HOST = environment.BASE_URL

    const url  = `${HOST}/user`
    return this.httpClient.get<User[]>(url, {
      params:
      {
        id_user
      }
    })
  }
}
