<app-step-bar [step]="currentPage"></app-step-bar>
<div class="tab tab8">
    <h2>Données enregistrées</h2>
    
    @if( new_saisine_abonne )
    {
        <p>Vous pouvez vous connecter à votre <a class="text-bold" href="{{lien_espace_abonne}}" target="_blank">espace abonné Médiation</a> pour consulter votre dossier.</p>
    }
    @else
    {
        <p>Vous allez recevoir prochainement un mail de confirmation.</p>
        <p>A défaut, n'oubliez pas de vérifier dans votre boîte mails le dossier spam/indésirable.</p>
    }
    <br>
    <p>
        @if(new_saisine_abonne)
        {
            Pour toutes demande d'informations, veuillez nous laissez un message via le formulaire contact sur le site de la médiation de l'eau.
        }
        @else
        {
            En cas de non-réception au-delà de 24h, veuillez nous laissez un message via le formulaire contact sur le site de la médiation de l'eau.
        }
        <br>
        <br>
        <a class="text-bold" href="assets/files/MDE_procedure_formulaire_de_contact.pdf" target="_blank" download id="procedure">La procédure pour accéder au formulaire de contact est disponible en cliquant ici</a>
    </p>
</div>




