import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { FormDataService } from '../services/form-data/form-data.service';
import { NavigationService } from '../services/navigation/navigation.service';
import { StepBarComponent } from "../step-bar/step-bar.component";
import { fileValidator } from '../custom_validators/file-validator';
import { faInfoCircle, faExclamation, faFileText, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { saisine, file_list } from '../form/form.component';
import { ScrollToTop } from '../helpers/decorator/afterEachNavigation';
import { removeHTMLTags } from '../helpers/functions/removeHTMLTags';
import { NoTagFichier } from '../helpers/functions/NoTagFichier';
import { splitLastSeparator } from '../helpers/functions/SplitLastSeparator';
import { checkValueForm } from '../helpers/functions/CheckValueForm';
import { Form } from '../class/Form';


@Component({
    selector: 'app-form6',
    standalone: true,
    templateUrl: './form6.component.html',
    imports: [CommonModule, ReactiveFormsModule, StepBarComponent, FontAwesomeModule]
})
export class Form6Component 
{
  form6!: FormGroup
  currentPage!: number
  isTextTooLong: boolean = false
  isValid!: boolean
  limit:number = 300
  info = faInfoCircle
  exclamation = faExclamation
  file = faFileText
  close = faClose
  fileList = file_list
  list_mandat:number[] = [11, 16, 18] //11: Proche (membre de la famille, ami, ...) | 16: Représentant légal (tutelle, curatelle, ...) | 18: Propriétaire ou locataire
  lien_abo_id!:number
  readonly allowedFiles:string[] = ["pdf","word","excel","png","jpeg","zip"]
  readonly maxDocuments = 15

  constructor
  (
    private formDataService: FormDataService,
    private navigationService: NavigationService
  )
  {
    this.lien_abo_id = Number(saisine['_lien_abo'])
    this.form6 = new FormGroup({
      file1: new FormControl(null,[Validators.required, fileValidator()]),

      file2: new FormControl(null, [Validators.required, fileValidator()]),
      checkbox2: new FormControl(false),

      file3: new FormControl(null, [Validators.required, fileValidator()]),
      checkbox3: new FormControl(false),

      file4: new FormControl(null),
      explication_doc4: new FormControl(saisine['_explication'],[Validators.maxLength(this.limit)]),

      file5: new FormControl(null),

      file6: new FormControl(null)
    })
  }

  ngOnInit()
  {
    this.checkPreviousPageForm(saisine, 4)
    this.checkPreviousPageForm(saisine, 5)

    this.clearFileList()

    if( this.list_mandat.includes(this.lien_abo_id) )
    {
      this.form6.get('file6')?.setValidators([Validators.required, fileValidator()])
    }
    else
    {
      this.form6.get('file6')?.clearValidators()
    }

    this.form6.get('file6')?.updateValueAndValidity()

    this.currentPage = this.navigationService.getCurrentPage()
    this.onChangeOnCheckbox(2) // Pour checkbox 2
    this.onChangeOnCheckbox(3) // Pour checkbox 3
  }

  clearFileList()
  {
    file_list['file1_list'] = []
    file_list['file2_list'] = []
    file_list['file3_list'] = []
    file_list['file4_list'] = []
    file_list['file5_list'] = []
    file_list['file6_list'] = []
  }

  checkPreviousPageForm(form:Form, etape:number)
  {
    const isFormValid = checkValueForm({form:form, etape:etape})
    if(isFormValid)
    {
      console.log(`form ${etape} is valid`)
      return
    }
    else
    {
      return this.navigationService.redirectTo(`formulaire_saisine/etape_${etape}`)
    }
  }

  onChangeFile(n:number, event: Event) 
  {
    const inputElement = event.target as HTMLInputElement
    
    if( inputElement.files && inputElement.files.length > 0)
    { 
      const maxSize = 10 * 1024 * 1024
      const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg']
      
      const notAllowedFiles: {file:string, type:string, size:number, error:string}[] = []
      const errorFileName:string[] = []
      const errorExtension:string[] = []

      if(inputElement.files.length > this.maxDocuments)
      {
          alert(`Limite du nombre de documents transmissibles atteintes (${this.maxDocuments} documents). \nVeuillez ajouter tous les documents dans une archive zip et transmettre l'archive seule`)
          return
      }

      const allowedFileTypes = Array.from(inputElement.files).filter(f => {
        if(!allowedTypes.includes(f.type))
        {
          notAllowedFiles.push({file: f.name, type: f.type, size:f.size, error: "Ce type de fichier n'est pas pris en compte"})
          return
        }
        return f
      })

      const allowedFileSize = Array.from(allowedFileTypes).filter(f => {
        if(f.size > maxSize)
        {
          notAllowedFiles.push({file: f.name, type: f.type, size:f.size, error: "Taille de fichier supérieure à 10 Mo"})
          return
        }
        return f
      })

      const allowedFile = allowedFileSize

      if( notAllowedFiles.length > 0 )
      {
        let alertString = notAllowedFiles.length > 1 ? "Ce fichier n'est pas autorisé pour la ou les raison(s) suivante(s) : \n " : "Ces fichiers ne sont pas autorisés pour les raisons suivantes : \n "
        notAllowedFiles.forEach(f => {
          alertString += `\n - ${f.file} : ${f.error} \n`
        })

        alert(alertString)
      }

      Array.from(allowedFile).forEach(element => {
        // const [fileName, extension] = element.name.split(".")
        const [fileName, extension] = splitLastSeparator(element.name, ".")

        if( fileName == "" || !fileName )
        {
            errorFileName.push(element.name)
            return
        }

        if( extension == "" || !extension )
        {
            errorExtension.push(element.name)
            return
        }

        const newfile = new File([element], NoTagFichier(fileName).concat(`.${extension}`), {type: element.type})
        file_list[`file${n}_list`].push(newfile)
      })

      if( errorFileName.length !== 0) 
      {
        alert(`Les fichiers ci-dessous : \n ${errorFileName.toString().replaceAll(",","\n")} \n ne seront pas transmis car ils possèdent une erreur dans leur nom`)
        return
      }

      if( errorExtension.length !== 0) 
      {
        alert(`Les fichiers ci-dessous : \n ${errorExtension.toString().replaceAll(",","\n")} \n ne seront pas transmis car ils possèdent une erreur dans leur extension`)
        return
      }

      this.form6.get(`file${n}`)?.patchValue(allowedFile)
    }
  }


  removeFileContainer(n: number) 
  {
    const inputFile: HTMLInputElement | null = document.querySelector(`#file${n}`)

    if( inputFile )
    {
      inputFile.value = ""
      this.form6.controls[`file${n}`].reset()
      file_list[`file${n}_list`] = []
    }
  }

  removeOneFile(n: number, index: number) 
  {
    file_list[`file${n}_list`]?.splice(index, 1)
  }

  isInvalid(controlName: string)
  {
    const control = this.form6.get(controlName)
    return control?.invalid && (control.dirty || control.touched)
  }

  activateCheckbox(n:number)
  {
    const checkbox = this.form6.get(`checkbox${n}`)?.value
    this.form6.get(`checkbox${n}`)?.patchValue(!checkbox)
  }

  onChangeOnCheckbox(n:number)
  {
    this.form6.get(`checkbox${n}`)?.valueChanges.subscribe( value => {
      const fileControl = this.form6.get(`file${n}`)
      const validators = [Validators.required, fileValidator()]

      if( value === false) 
      {
        fileControl?.setValidators(validators)
      }
      else 
      {
        fileControl?.clearValidators()
        this.removeFileContainer(n) 
      }
      
      this.form6.get(`file${n}`)?.updateValueAndValidity()
    })
  }

  @ScrollToTop()
  saveFormAndNavigate(n:number)
  {
    if( n === -1 ) this.navigationService.redirect(n)
    
    if (this.form6.invalid)
    {
      if(this.form6.get('checkbox2')?.value === true)
      {
        this.form6.controls['file1'].markAsTouched()
        this.form6.controls['file3'].markAsTouched()
        this.form6.controls['file4'].markAsTouched()
        this.form6.controls['file5'].markAsTouched()
      }
      else if(this.form6.get('checkbox3')?.value === true)
      {
        this.form6.controls['file1'].markAsTouched()
        this.form6.controls['file2'].markAsTouched()
        this.form6.controls['file4'].markAsTouched()
        this.form6.controls['file5'].markAsTouched()
      }
      else this.form6.markAllAsTouched()
      this.form6.markAllAsTouched()
      this.isValid = false
    }
    else
    {
      const form = this.form6.value
      saisine['_explication'] = removeHTMLTags(form.explication_doc4)

      this.formDataService.saveFormData(saisine)
      this.navigationService.redirect(n)
    }
  }
}
