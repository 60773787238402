import { removeHTMLTags } from "../helpers/functions/removeHTMLTags"

export class Form 
{
    // [key:string]: string | number | File[]
    private _civilite_abo!: number
    private _lien_abo!: number
    private _nom_abo!: string
    private _prenom_abo!: string
    private _email_abo!: string
    private _societe_organisme_abo!: string
    private _ref_dossier!: string
    private _civilite!: number
    private _nom!: string
    private _prenom!: string
    private _email!: string
    private _societe_organisme!: string
    private _type_contrat!: number
    private _info_eau_potable!: string
    private _info_assainissement_collectif!: string
    private _litige!: string
    private _attentes!: string
    private _adresse!: string
    private _cp!: string
    private _ville!: string
    private _pays!: number // ID = 1 => France
    private _adresse_correspondance!: string
    private _cp_correspondance!: string
    private _ville_correspondance!: string
    private _pays_correspondance!: number // ID = 1 => France
    private _complement_adresse_correspondance!: string
    private _explication!: string
    private _new_saisine_abonne!: boolean

    get civilite_abo() {
      return this._civilite_abo
    }
    
    set civilite_abo(val: number) {
      this._civilite_abo = val
    }

    get lien_abo() {
      return this._lien_abo
    }
    
    set lien_abo(val: number) {
      this._lien_abo = val
    }
    
    get nom_abo() {
      return removeHTMLTags(this._nom_abo)
    }
    
    set nom_abo(val: string) {
      this._nom_abo = removeHTMLTags(val)
    }
    
    get prenom_abo() {
      return removeHTMLTags(this._prenom_abo)
    }
    
    set prenom_abo(val: string) {
      this._prenom_abo = removeHTMLTags(val)
    }
    
    get email_abo() {
      return removeHTMLTags(this._email_abo)
    }
    
    set email_abo(val: string) {
      this._email_abo = removeHTMLTags(val)
    }
    
    get societe_organisme_abo() {
      return removeHTMLTags(this._societe_organisme_abo)
    }
    
    set societe_organisme_abo(val: string) {
      this._societe_organisme_abo = removeHTMLTags(val)
    }
    
    get ref_dossier() {
      return removeHTMLTags(this._ref_dossier)
    }
    
    set ref_dossier(val: string) {
      this._ref_dossier = removeHTMLTags(val)
    }
    
    get civilite() {
      return this._civilite
    }
    
    set civilite(val: number) {
      this._civilite = val
    }
    
    get nom() {
      return removeHTMLTags(this._nom)
    }
    
    set nom(val: string) {
      this._nom = removeHTMLTags(val)
    }
    
    get prenom() {
      return removeHTMLTags(this._prenom)
    }
    
    set prenom(val: string) {
      this._prenom = removeHTMLTags(val)
    }
    
    get email() {
      return removeHTMLTags(this._email)
    }
    
    set email(val: string) {
      this._email = removeHTMLTags(val)
    }
    
    get societe_organisme() {
      return removeHTMLTags(this._societe_organisme)
    }
    
    set societe_organisme(val: string) {
      this._societe_organisme = removeHTMLTags(val)
    }

    get type_contrat() {
      return this._type_contrat
    }
    
    set type_contrat(val: number) {
      this._type_contrat = val
    }

    get info_eau_potable() {
      return removeHTMLTags(this._info_eau_potable)
    }
    
    set info_eau_potable(val: string) {
      this._info_eau_potable = removeHTMLTags(val)
    }

    get info_assainissement_collectif() {
      return removeHTMLTags(this._info_assainissement_collectif)
    }
    
    set info_assainisseùent_collectif(val: string) {
      this._info_assainissement_collectif = removeHTMLTags(val)
    }
    
    get litige() {
      return removeHTMLTags(this._litige)
    }
    
    set litige(val: string) {
      this._litige = removeHTMLTags(val)
    }
    
    get attentes() {
      return removeHTMLTags(this._attentes)
    }
    
    set attentes(val: string) {
      this._attentes = removeHTMLTags(val)
    }
    
    get adresse() {
      return removeHTMLTags(this._adresse)
    }
    
    set adresse(val: string) {
      this._adresse = removeHTMLTags(val)
    }
    
    get cp() {
      return removeHTMLTags(this._cp)
    }
    
    set cp(val: string) {
      this._cp = removeHTMLTags(val)
    }
    
    get ville() {
      return removeHTMLTags(this._ville)
    }
    
    set ville(val: string) {
      this._ville = removeHTMLTags(val)
    }
    
    get pays() {
      return this._pays
    }
    
    set pays(val: number) {
      this._pays = val
    }
    
    get adresse_correspondance() {
      return removeHTMLTags(this._adresse_correspondance)
    }
    
    set adresse_correspondance(val: string) {
      this._adresse_correspondance = removeHTMLTags(val)
    }
    
    get cp_correspondance() {
      return removeHTMLTags(this._cp_correspondance)
    }
    
    set cp_correspondance(val: string) {
      this._cp_correspondance = removeHTMLTags(val)
    }
    
    get ville_correspondance() {
      return removeHTMLTags(this._ville_correspondance)
    }
    
    set ville_correspondance(val: string) {
      this._ville_correspondance = removeHTMLTags(val)
    }
    
    get pays_correspondance() {
      return this._pays_correspondance
    }
    
    set pays_correspondance(val: number) {
      this._pays_correspondance = val
    }
    
    get complement_adresse_correspondance() {
      return removeHTMLTags(this._complement_adresse_correspondance)
    }
    
    set complement_adresse_correspondance(val: string) {
      this._complement_adresse_correspondance = removeHTMLTags(val)
    }
    
    get explication() {
      return removeHTMLTags(this._explication)
    }
    
    set explication(val: string) {
      this._explication = removeHTMLTags(val)
    }

    get new_saisine_abonne() {
      return this._new_saisine_abonne
    }
    
    set new_saisine_abonne(val: boolean) {
      this._new_saisine_abonne = val
    }


    constructor()
    {
        this._civilite_abo = 0,
        this._lien_abo = 0,
        this._nom_abo = '',
        this._prenom_abo = '',
        this._email_abo = '',
        this._societe_organisme_abo = '',
        this._ref_dossier = '',
        this._civilite = 0,
        this._nom = '',
        this._prenom = '',
        this._email = '',
        this._societe_organisme = '',
        this._type_contrat = 0,
        this._info_eau_potable = '',
        this._info_assainissement_collectif = '',
        this._litige = '',
        this._attentes = '',
        this._adresse = '',
        this._cp = '',
        this._ville = '',
        this._pays = 1, // ID = 1 => France
        this._adresse_correspondance = '',
        this._cp_correspondance = '',
        this._ville_correspondance = '',
        this._pays_correspondance = 0, // ID = 1 => France
        this._complement_adresse_correspondance = '',
        this._explication = ''
        this._new_saisine_abonne = false
    }


}